import "@servicetitan/anvil-fonts/dist/css/anvil-fonts.css";
import "@servicetitan/design-system/dist/system.min.css";
import { BreakPoint } from "@servicetitan/design-system";
import { DesktopLandingPage } from "./components/DesktopLandingPage";
import { MobileLandingPage } from "./components/MobileLandingPage";
import { NotFoundPage } from "./components/NotFoundPage";
import { useState, useEffect } from "react";
import {
  IClientState,
  IGetRwgConfigResponseV1,
  IGetRwgConfigResponseV2,
} from "./types/App";
import { InitialPage } from "./components/InitialPage";
import { getReserveWithGoogleToken } from "./utils/getReserveWithGoogleToken";
import * as Sentry from "@sentry/react";

function App() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
  });

  const [clientData, setClientData] = useState<IClientState>();

  const isV2 = "schedulerSrc" in (clientData?.data ?? {});
  const { addressLine, name } = clientData?.data || {
    addressLine: "",
    name: "",
  };

  // V1 fields
  const clientKey = isV2
    ? ""
    : (clientData?.data as IGetRwgConfigResponseV1)?.clientKey;
  const flowKey = isV2
    ? ""
    : (clientData?.data as IGetRwgConfigResponseV1)?.flowKey;
  const mediaUrl = isV2
    ? ""
    : (clientData?.data as IGetRwgConfigResponseV1)?.mediaUrl;

  // V2 field
  const schedulerSrc = isV2
    ? (clientData?.data as IGetRwgConfigResponseV2)?.schedulerSrc
    : "";

  useEffect(() => {
    const fetchRwgConfig = async () => {
      const splitRoute = window.location.pathname.split("/");
      const gbpIdOrToken = splitRoute[splitRoute.length - 1].split("?")[0];
      // if it is a gbpId, it will have the format gbp_XXXXX
      const isGbpId = gbpIdOrToken.startsWith("gbp_");
      // get rwg_token from query params
      const rwgToken = getReserveWithGoogleToken();

      if (isGbpId) {
        setClientData({
          data: undefined,
          requestSuccess: false,
        });

        return;
      }

      if (rwgToken !== null) {
        const res = await fetch(process.env.REACT_APP_SCHEDULER_API || "", {
          body: JSON.stringify({
            query: `
            query GetRwgConfig($rwgToken: String!, $gbpId: ID, $token: String) {
              getRwgConfig(rwgToken: $rwgToken, gbpId: $gbpId, token: $token) {
                addressLine
                clientKey
                flowKey
                mediaUrl
                name
                schedulerSrc
              }
            }
          `,
            variables: {
              rwgToken,
              token: isGbpId ? undefined : gbpIdOrToken,
            },
          }),
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (res.ok) {
          const resJson = await res.json();

          if (resJson.errors) {
            const body = JSON.stringify(resJson);
            Sentry.setExtra("body", { ...resJson.errors });
            Sentry.captureException(
              new Error(`Error fetching GetRwgConfig: ${body}`)
            );
            setClientData({
              data: undefined,
              requestSuccess: false,
            });
          } else {
            const data: IGetRwgConfigResponseV1 | IGetRwgConfigResponseV2 =
              resJson.data.getRwgConfig;

            const dataToSet: Partial<
              IGetRwgConfigResponseV1 | IGetRwgConfigResponseV2
            > = {
              addressLine: data.addressLine,
              name: data.name,
              ...("schedulerSrc" in data && data.schedulerSrc
                ? { schedulerSrc: data.schedulerSrc }
                : {}),
              ...("clientKey" in data && data.clientKey
                ? { clientKey: data.clientKey }
                : {}),
              ...("flowKey" in data && data.flowKey
                ? { flowKey: data.flowKey }
                : {}),
              ...("mediaUrl" in data && data.mediaUrl
                ? { mediaUrl: data.mediaUrl }
                : {}),
            };

            setClientData({
              data: dataToSet as
                | IGetRwgConfigResponseV1
                | IGetRwgConfigResponseV2,
              requestSuccess: true,
            });
          }
        } else {
          const resJson = await res.json();
          const body = JSON.stringify(resJson);
          Sentry.setExtra("body", { ...resJson.errors });
          Sentry.captureException(
            new Error(`GetRwgConfig failed with ${res.status}: ${body}`)
          );
          setClientData({
            data: undefined,
            requestSuccess: false,
          });
        }
      } else {
        setClientData({
          data: undefined,
          requestSuccess: false,
        });
        Sentry.captureException(new Error("`rwg_token` is missing."));
      }
    };

    fetchRwgConfig();
  }, []);

  useEffect(() => {
    if (name) {
      document.title = name;
    }
  }, [name]);

  if (!clientData) {
    return <InitialPage />;
  }

  if (!clientData.requestSuccess) {
    return <NotFoundPage />;
  }

  return (
    <Sentry.ErrorBoundary>
      <BreakPoint max="mobile">
        <MobileLandingPage
          addressLine={addressLine}
          name={name}
          {...(isV2 ? { schedulerSrc } : { clientKey, flowKey, mediaUrl })}
        />
      </BreakPoint>
      <BreakPoint min="mobile">
        <DesktopLandingPage
          addressLine={addressLine}
          name={name}
          {...(isV2 ? { schedulerSrc } : { clientKey, flowKey, mediaUrl })}
        />
      </BreakPoint>
    </Sentry.ErrorBoundary>
  );
}

export default App;
